import React from "react";
import Layout from "../layouts/Layout";
import SiteMetadata from "../components/SiteMetadata";
import HeadingTop from "../components/BuildingBlocks/HeadingTop";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import makeUrl from "../utils/url";
import EventList from "../components/EventList";

function Person({ data }) {
  const nodes = data.persons.nodes;

  return (
    <Layout>
      <SiteMetadata
        title="Handverlesene Geschenkideen für deinen Liebling"
        description="Lass dich inspirieren von vielen tollen Geschenkideen für Mama, Papa, Oma und viel mehr!"
      />

      <HeadingTop
        title="Geschenkideen für Deinen Liebling"
        subtitle="Alle Personen im Überblick."
      />

      <div className="container-xl font-display text-xl my-4">
        <div className="flex flex-wrap justify-center -mb-8">
          {nodes.map((item, i) => (
            <Link
              key={i}
              to={`/${makeUrl(
                item.childContentfulPersonTitleTextNode.title,
                "person"
              )}`}
            >
              <div className="flex flex-col items-center p-4 group">
                <div className="h-16 w-16 md:w-20 md:h-20 lg:h-24 lg:w-24 mb-4 duration-300 transition transform hover:scale-110">
                  <GatsbyImage
                    image={item.image[0].gatsbyImageData}
                    alt="vild"
                    className="w-full h-full object-cover rounded-full"
                  />
                </div>
                <div>{item.childContentfulPersonTitleTextNode.title}</div>
              </div>
            </Link>
          ))}
        </div>
        <EventList items={data.events.nodes} />
      </div>
    </Layout>
  );
}

export default Person;

export const query = graphql`
  query PersonPageQuery {
    events: allContentfulAnlass(sort: { order: ASC, fields: date }) {
      nodes {
        date(locale: "de", formatString: "DD. MMMM")
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        title
      }
    }
    persons: allContentfulPerson {
      nodes {
        childContentfulPersonTitleTextNode {
          title
        }
        image {
          gatsbyImageData
        }
      }
    }
  }
`;
